.skills-container{
  min-height: 100vh;
}

.loader {
  animation: slideFromLeft 1.5s;
}


/* @media only screen and (max-device-width: 500px) {
  .skills {
    display: flex;
    flex-direction: column;
  }
  .skill {
    margin-top: 50px;
  }
} */
