
.loader {
  animation: slideFromLeft 1.5s;
}
.image {
  height: auto;
  width: 40%;
}


