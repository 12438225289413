.icon {
  height: auto;
  width: 7%;
}


@media only screen and (max-device-width: 500px) {
  .icon {
    width: 25%;
  }


}