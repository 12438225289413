@keyframes slideFromRight {
  from {
    transform: translateX(100%); 
    opacity: 0; 
  }
  to {
    transform: translateX(0%); 
    opacity: 1; 
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%); 
    opacity: 0; 
  }
  to {
    transform: translateX(0%); 
    opacity: 1; 
  }
}